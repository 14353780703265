body {
    padding: 0px;
    margin: 0px;
    font-family: "Outfit", system-ui;
    font-optical-sizing: auto;
    font-style: normal;
    width: "100%";
}

p {
    padding: 0px;
    margin: 0px;
}

h1 {
    margin: 0px;
    font-size: 20px;
}

table {
    width: 100%;
    padding: 0px;
    border: 0;
}

tr {
    padding: 10px;
}

td {
    padding: 10px 20px;
    border-right: 1px solid gainsboro;
}

td:last-child {
    border: none;
}

.tdkey {
    font-weight: 500;
    width: 1%;
    white-space: nowrap;
    color: gray;
}

/* .tdval {

} */

.backgroundGrad {
    background: rgb(240, 240, 240);
    background: linear-gradient(149deg, rgba(240, 240, 240, 1) 0%, rgba(255, 234, 168, 1) 49%, rgba(211, 237, 252, 1) 100%);
}

.container_center_flex {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.updateContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: left;
    justify-content: center;
    width: 100%;
}

.updateItem {
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-bottom: 1px solid widgetCardlightgray;
}

.dateContainer {
    font-size: 20px;
    margin-bottom: 20px;
    color: gray;
}

.contentContainer {
    padding: 0;
}



.header {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    gap: 10px;
    padding: 30px;
    background-color: ghostwhite;
}

.logo {
    font-size: 20px;
}

.imageContainer {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
}

.image {
    width: 100%;
    height: auto;
}

.dropShadowMain {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.dropShadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.widgetCard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 11px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 2px;
}

@media screen and (max-width: 767px) {
    .gallery {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        grid-gap: 2px;
    }
}

.gallery__item {
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 2px;
    margin-bottom: 2px;
}

.custom_input {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.input {
    font-family: "Outfit", system-ui;

    font-size: 16px;
    padding: 8px 10px;
    width: 100%;
    padding-left: 35px;
    outline: none;
    background: #FFFFFF;
    color: #000000;
    border: 1px solid #D3D3D3;
    border-radius: 4px;
    box-shadow: 2px 3px 1px -1px #E2E2E2;
    transition: .3s ease;
}

.input:focus {
    background: #F2F2F2;
    border: 1px solid #939493;
    border-radius: 10px;
}

.input::placeholder {
    color: #DDDDDD;
}

.svg_icon {
    position: absolute;
    left: 10px;
    fill: #727272;
    width: 16px;
    height: 16px;
}

.button {
    font-family: "Outfit", system-ui;

    font-size: 14px;
    padding: 8px 10px;
    outline: none;
    background: #FFFFFF;
    color: #000000;
    border: 1px solid #D3D3D3;
    border-radius: 4px;
    box-shadow: 2px 3px 1px -1px #E2E2E2;
    transition: .3s ease;
}

.button:hover {
    background: #F2F2F2;
    border: 1px solid #939493;
    border-radius: 10px;
    cursor: pointer;
}